<template>
  <div>
    <a-modal width="70%" :title="specificationTile" :visible="visible" :ok-button-props="{ danger: true }" :confirm-loading="confirmLoading" footer @ok="cancel" @cancel="cancel">
      <pagaTable ref="table" :columns="purchaseColumns" :sourceData="getDataApi" row-key="attributeValueId" @change="handleChange">
        <template #headerLeft>
          <a-button type="default" class="ant-btn-add" @click="handleAdd">
            新增
          </a-button>
        </template>
        <template #operation="{ text, record }">
          <a-button type="link" @click="handleClick(text, record)">编辑</a-button>
          <a-popconfirm title="确定删除吗" ok-text="是的" cancel-text="关闭" @confirm="del(record)">
            <a-button type="link">删除</a-button>
          </a-popconfirm>
        </template>
      </pagaTable>
    </a-modal>
    <Form ref="popupWindow" :Title="Title" @refresh="refresh"></Form>
  </div>
</template>
<script>
import Form from './form.vue'
import { defineComponent } from 'vue'
import pagaTable from '@/components/pagination-table/index'
import {
  AttributeValueController_page,
  AttributeValueController_del
} from '@/api/system'
const form = {
  label: '',
  value: '',
  sort: ''
}
export default defineComponent({
  name: 'index',
  props: {
    specificationTile: {
      type: String,
      required: false
    },
    FormData: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      visible: false,
      Title: '',
      formState: {
        ...form
      },
      editor: false,
      purchaseColumns: [
        {
          title: '属性名称 ',
          dataIndex: 'name'
        },
        {
          title: '备注',
          dataIndex: 'comment'
        },
        {
          title: '操作',
          dataIndex: 'operation',
          slots: { customRender: 'operation' }
        }
      ]
    }
  },
  components: {
    pagaTable,
    Form
  },
  created() {},
  methods: {
    handleAdd() {
      this.Title = '新增'
      this.$refs.popupWindow.showModal(this.formState.attributeId)
    },
    handleClick(text, row) {
      this.Title = '编辑'
      this.$refs.popupWindow.showModal(row)
    },
    refresh() {
      this.$refs.table.refresh()
    },
    getDataApi(params) {
      return new Promise((reslove) => {
        const params_api = {
          currentPage: params.pageIndex,
          pageSize: params.pageSize,
          attributeId: this.formState.attributeId
        }
        AttributeValueController_page(params_api).then((res) => {
          reslove(res)
        })
      })
    },
    del(val) {
      AttributeValueController_del({ id: val.attributeValueId }).then((res) => {
        this.refresh()
        this.$message.success('操作成功')
      })
    },
    cancel() {
      this.formState = { ...form }
      this.editor = false
      this.visible = false
    },
    showModal(val) {
      this.visible = true
      if (val) {
        this.editor = true
        this.formState = { ...form, ...val }
      } else {
        this.editor = false
      }
      this.refresh()
    }
  }
})
</script>
<style lang="less">
.page-wrapper {
  padding: 20px 32px;
}

.radio {
  width: 100px;
  height: 35px;
  line-height: 35px;
  text-align: center;
  border: 1px solid #e0e0e2;
}
</style>
    